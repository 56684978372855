<template>
  <div id="selectRegisterCorporate">
    <div class="container">
      <div class="row justify-content-center vh-100">
        <div class="col-lg-10 col-md-10 col-12 align-self-center">
          <div class="card">
            <div class="card-body login-card-body p-0 m-0">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12 py-5 px-5">
                  <div class="logoBox text-center">
                    <img
                      class="h35"
                      src="../../assets/img/logo.png"
                      style="height: 100px"
                    />
                  </div>

                  <div class="mt-5">
                    <form v-on:submit.prevent="fnRegister">
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.business_name"
                        />
                        <label class="floatingLabel">Business name</label>
                        <div
                          class="invalid-feedback"
                          v-if="errors.business_name"
                        >
                          {{ errors.business_name[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.position_title"
                        />
                        <label class="floatingLabel">Position title</label>
                        <div
                          class="invalid-feedback"
                          v-if="errors.position_title"
                        >
                          {{ errors.position_title[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.first_name"
                        />
                        <label class="floatingLabel">First name</label>
                        <div class="invalid-feedback" v-if="errors.first_name">
                          {{ errors.first_name[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.last_name"
                        />
                        <label class="floatingLabel">Last name</label>
                        <div class="invalid-feedback" v-if="errors.last_name">
                          {{ errors.last_name[0] }}
                        </div>
                      </div>

                      <!-- Int Tell Country -->
                      <div class="form-group multiselect">
                        <b-form-select
                          value-field="dial_code"
                          text-field="name"
                          placeholder=" "
                          :options="optionsIntTel"
                          v-model="user.int_tel_code"
                        >
                        </b-form-select>
                        <label class="floatingLabel vueBootSelect"
                          >Country</label
                        >
                        <div
                          class="invalid-feedback col-12"
                          v-if="errors.int_tel_code"
                        >
                          {{ errors.int_tel_code[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=" "
                          v-model="user.mobile_no"
                        />
                        <label class="floatingLabel"
                          >Mobile number
                          <span class="fs-700 text-danger"
                            >(Without country code)</span
                          ></label
                        >
                        <div class="invalid-feedback" v-if="errors.mobile_no">
                          {{ errors.mobile_no[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-3">
                        <input
                          type="email"
                          class="form-control"
                          placeholder=" "
                          v-model="user.email"
                        />
                        <label class="floatingLabel vueBootSelect">Email</label>
                        <div class="invalid-feedback" v-if="errors.email">
                          {{ errors.email[0] }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <div class="icheck-primary">
                            <b-form-checkbox
                              id="checkbox-1"
                              v-model="user.terms_status"
                              name="checkbox-1"
                              value="1"
                              unchecked-value=""
                            >
                              I accept the
                              <a
                                href="https://cvscreen.com.au/terms-conditions/"
                                target="_blank"
                                >terms and use</a
                              >
                            </b-form-checkbox>
                          </div>
                          <div
                            class="invalid-feedback"
                            v-if="errors.terms_status"
                          >
                            {{ errors.terms_status[0] }}
                          </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-4">
                          <button type="submit" class="btn btn-info btn-block">
                            <b-spinner
                              small
                              v-if="loading"
                              class="mr-2"
                            ></b-spinner>
                            Register
                          </button>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>
                  </div>

                  <div class="mt-3">
                    <p class="mb-1">
                      <router-link to="/" class="text-center"
                        >I already have a membership</router-link
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-6 col-12 bg-secondary"
                  id="registerCorporateBoxImg"
                >
                  <div class="p-4">
                    <h4 class="mt2000">Why create a Business account?</h4>
                    <p>
                      CV Screen’s simple and comprehensive background checks
                      allow businesses to make informed hiring decisions.
                    </p>
                    <div class="row">
                      <div class="col-1">
                        <p class="mb-0"><i class="fas fa-angle-right"></i></p>
                      </div>
                      <div class="col-11">
                        <p class="mb-0">
                          User friendly client portal allows your business to
                          order background checks quickly and easily.
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-1">
                        <p class="mb-0"><i class="fas fa-angle-right"></i></p>
                      </div>
                      <div class="col-11">
                        <p class="mb-0">
                          Organised dashboard to display the status and results
                          of background checks.
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-1">
                        <p class="mb-0"><i class="fas fa-angle-right"></i></p>
                      </div>
                      <div class="col-11">
                        <p class="mb-0">
                          Real time notifications and status updates.
                        </p>
                      </div>
                    </div>
                    <img src="../../assets/img/corporate-user-4912880.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import { intTel } from "../../services/data.service";
export default {
  data() {
    return {
      errors: {},
      loading: false,
      optionsIntTel: intTel,
      user: {
        business_name: "",
        position_title: "",
        first_name: "",
        last_name: "",
        int_tel_code: "+61",
        mobile_no: "",
        email: "",
        terms_status: "",
      },
    };
  },
  methods: {
    fnRegister: async function () {
      try {
        this.loading = true;
        // Progress bar
        this.$Progress.start();

        const res = await authService.registerCM(this.user);

        if (res.data.status_code === 200) {
          this.$router.push("/welcome-message-cm");
        }

        // Progress bar
        this.$Progress.finish();

        this.errors = {};
      } catch (error) {
        console.log(error);
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Registration",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Registration",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Registration",
              "Something wrong, please try again!"
            );
            break;
        }
        // Progress fail (error)
        this.$Progress.fail();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  #registerCorporateBoxImg img {
    padding-top: 20px;
    position: inherit;
    right: 0;
    bottom: 0;
    height: 270px;
  }
}
</style>
